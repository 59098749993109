import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import des1 from '../../Assets/images/c1.jpg'
import des2 from '../../Assets/images/c2.jpg'
import des3 from '../../Assets/images/c3.jpg'

const data=[
    {
        img: des1,
        title: "Diamond",
        url: "/diamonds",
        text: "From modern to vintage inspired designs, our extensive collection is sure to hold a ring that meets your style and budget."
    },
    {
        img: des2,
        title: "Custom Design",
        url: "/custom-design",
        text: "Our designers use your ideas, drawings, and images to visualize your exclusive design concept."
    },
    {
        img: des3,
        title: "Delivery",
        url: "#",
        text: "Once you’ve chosen your setting and diamond, you’ll be able to review your completed ring. You can even add engraving to personalize your choice further."
    }
];
const CustomDesign = () => {
  return (
   <section className='pt-4'>
     <Container className="rcs_custom_home_container">
     <div className="rcs_ringstyle_title tri_text_capitalize text-center">
                <h2 className='fontOptiCentury'>Custom Design</h2>

                <p className="subtitle text-center fs18 mb-5">Create a beautiful, one-of-a-kind representation of your unique love. </p>
              </div>

              <Row>
                {
                    data.map((e,i)=>{
                        return <Col key={i}>
                        <div className="text-center">
                        <div className='mb-3'>
                            <Link to={e.url}>
                                <img src={e.img} alt={e.title} />
                            </Link>
                            </div>
                            <h3>
                            <Link to={e.url} className='titleUnderline Karla'>
                            {e.title}
                            </Link>
                            </h3>
                            <p>
                                {
                                    e.text
                                }
                            </p>
                        </div>
                       
                        </Col>
                    })
                }
              </Row>
     </Container>
   </section>
  )
}

export default CustomDesign
