import React from 'react'
import { Container, Row, Col, FormControl, Button, Image } from 'react-bootstrap'
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Social1 from '../../Assets/images/icon/fb.png'
import Social2 from '../../Assets/images/icon/tw.png'
import Social3 from '../../Assets/images/icon/pt.png'
import Social4 from '../../Assets/images/icon/inst.png'
import Social5 from '../../Assets/images/icon/ganna.png'
import linkden from '../../Assets/images/icon/linkdin.png'

const TriSignUp = () => {
  return (
   <>
    <section className='pt-0'>
    <Container className='rcs_custom_home_container'>
        <div className='tri_signup_section'>
             
                <Row className='align-center'>
                    <Col sm={12} lg={7} md={7} className='mb_xs_20'>
                            <div className="rcs_signup_to_left_main">
                                <div className="rcs_signup_to_title">
                                    <p className='mb-0 fw700' >Sign Up For Exclusive Offers</p>
                                </div>
                                <div className="rcs_signup_to_input_field">
                                    <Form className="gs_news_form w-100">
                                        <InputGroup className="">
                                            <FormControl
                                                placeholder="Email Address..."
                                                aria-label="Recipient's username"
                                                aria-describedby="basic-addon2"
                                                type="email"
                                                required
                                            />
                                            <Button variant="outline-secondary" type="submit" id="button-addon2">
                                            Sign Up
                                            </Button>
                                        </InputGroup>
                                    </Form>
                                </div>
                            </div>
                        </Col>
                    <Col sm={12} md={5} lg={5}>
                        <div className='tri_social_icons_box_outer'>
                                 <div className='tri_social_text_follow mr-3 rcs_signup_to_title'>
                                      <p className='mb-0 fw700'>Follow Us On:</p>
                                 </div>
                                 <div className='tri_social_follow_icons'>
                                     <a href='https://www.facebook.com/profile.php?id=100083864271889' target="_blank"><Image src={Social1} alt="Facebook Image"/></a> 
                                     <a href='https://twitter.com/DilseJewels' target="_blank"><Image src={Social2} alt="Twitter Image"/></a> 
                                     <a href='https://www.linkedin.com/in/dil-se-jewels-3546a326a/' target="_blank"><Image src={linkden} alt="Pintrest Image"/></a>
                                     <a href='https://www.instagram.com/dilse_jewels/' target="_blank"><Image src={Social4} alt="Instagram Image"/></a>
                                     {/* <a href='#' target="_blank"><Image src={Social5} alt="Player Image"/></a>  */}
                                 </div>
                        </div>
                    </Col>
                </Row>
           
        </div>
        </Container>
    </section>
   </>
  )
}

export default TriSignUp