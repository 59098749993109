import React, { useEffect } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../../Assets/css/home.css";
import Slider from "react-slick";
import { isMobile, isMobileOnly } from "react-device-detect";
import { Button } from "@mui/material";
import { useHistory } from "react-router-dom";
import { Col, Container, Row } from "react-bootstrap";
import LabBanner from "../../Assets/images/dill-se/Lab-Grown-Diamonds.png";
import LoveBanner from "../../Assets/images/dill-se/Perfect-Symbolo-Love.png";
import FashionBanner from "../../Assets/images/dill-se/Fashion-Diamond-Rings.png";

const BannerSlider = () => {
  const history = useHistory();
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    accessibility: true,
    arrows: true,
    autoplay: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
          breakpoint: 600,
          settings: {
             dots: false,
          }
      },
    ]
  };
  const banner = localStorage.getItem("dilse-bannerdata")
    ? JSON.parse(localStorage.getItem("dilse-bannerdata"))
    : [];
  const bannerimg = isMobileOnly ? banner[0]?.mobile_image : banner[0]?.image;
  return (
    <>
      {/* <Slider className="rcs_slider_img" {...settings}>
        {banner?.map((val, index) =>
          <div>
            {isMobileOnly ?
              <img src={val.mobile_image} /> : <img src={val.image} />}
          </div>
        )}
      </Slider> */}
      {/* {banner?.map((val, index) =>
        <div className="rcs_hero_slider" style={{ backgroundImage: `url(${isMobileOnly ? val?.mobile_image : val?.image})` }}>
          <div className="rcs_hero_img">
            <Container fluid>
              <Row className='m-auto w-100'>
                <Col md={9} className='m-auto'>
                  <div className={isMobile ? 'text-center' : val.text_direction_class}>
                    <h1 data-aos="fade-up-left" data-aos-duration="700">{(val.title)}</h1>
                    <p data-aos="fade-up-left" data-aos-duration="1200" data-aos-delay="300">{(val.short_text)}</p>
                    <Button data-aos="fade-up-left" data-aos-duration="1800" data-aos-delay="400" onClick={() => { history.push('/' + val.btn_link) }} variant="outline-dark">{val.btn_text}</Button>
                  </div>                  
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      )} */}
      {/* trilliant banner start*/}
      <div className="tri_banner_section_outer_box">
        <Container className="rcs_custom_home_container">
         <Row>
          <Col>
          <Slider {...settings}>

            <div className="rcs_hero_slider tri_hero_slider">
              <img src={LabBanner} className="img-fluid w-100" alt="" />
              <div className="rcs_hero_img">
                <Row className="m-auto w-100">
                  <Col md={9} className="m-auto">
                    <div className="">
                      <h1
                        data-aos="fade-up-left"
                        data-aos-duration="700"
                        className="fontGreatVibes"
                      >
                       Lab Grown Diamonds
                      </h1>
                      <p
                        data-aos="fade-up-left"
                        data-aos-duration="1200"
                        data-aos-delay="300"
                        className=""
                      >
                       Explore the latest Lab Grown Diamonds
                      </p>
                      <span
                        data-aos="fade-up-left"
                        data-aos-duration="1800"
                        data-aos-delay="400"
                        onClick={() => {
                          history.push("/diamonds/lab");
                        }}
                        variant="outline-dark"
                        className="cursor sameWidth card_button px-5 py-2"
                      >
                       Shop Diamonds
                      </span>
                
                    </div>
                  </Col>
                </Row>
              </div>
            </div>

            <div className="rcs_hero_slider tri_hero_slider">
              <img src={LoveBanner} className="img-fluid w-100" alt="" />
              <div className="rcs_hero_img">
                <Row className="m-auto w-100">
                  <Col md={9} className="m-auto">
                    <div className="center_slide">
                      <h1
                        data-aos="fade-up-left"
                        data-aos-duration="700"
                        className="fontGreatVibes"
                      >
                        Perfect Symbol of Love
                      </h1>
                      <p
                        data-aos="fade-up-left"
                        data-aos-duration="1200"
                        data-aos-delay="300"
                        className=""
                      >
                       Explore the latest Wedding collection
                      </p>
                      <span
                        data-aos="fade-up-left"
                        data-aos-duration="1800"
                        data-aos-delay="400"
                        onClick={() => {
                          history.push("/jewelry/wedding-rings");
                        }}
                        variant="outline-dark"
                        className="cursor sameWidth card_button px-5 py-2"
                      >
                       Shop Wedding Rings
                      </span>
                     
                    </div>
                  </Col>
                </Row>
              </div>
            </div>

            <div className="rcs_hero_slider tri_hero_slider">
              <img src={FashionBanner} className="img-fluid w-100" alt="" />
              <div className="rcs_hero_img">
                <Row className="m-auto w-100">
                  <Col md={9} className="m-auto">
                    <div className="">
                      <h1
                        data-aos="fade-up-left"
                        data-aos-duration="700"
                        className="fontGreatVibes"
                      >
                  Fashion Diamond Rings
                      </h1>
                      <p
                        data-aos="fade-up-left"
                        data-aos-duration="1200"
                        data-aos-delay="300"
                        className=""
                      >
                        Explore the latest Fashion Diamond Rings
                      </p>
                      <span
                        data-aos="fade-up-left"
                        data-aos-duration="1800"
                        data-aos-delay="400"
                        onClick={() => {
                          history.push("/jewelry/fashion-rings");
                        }}
                        variant="outline-dark"
                        className="cursor sameWidth card_button px-5 py-2"
                      >
                       Shop Diamond Rings
                      </span>
                     
                    </div>
                  </Col>
                </Row>
              </div>
            </div>

          </Slider>
          </Col>
         </Row>
        </Container>
      </div>

      {/* trilliant banner end*/}
    </>
  );
};

export default BannerSlider;
