import React from 'react';
import { Container, Row, Col, NavLink, Button } from 'react-bootstrap';
import Gimg from "../../Assets/images/home/google-img.webp";
import userImg from "../../Assets/images/home/slide-user-img.png";
import googleReview from "../../Assets/images/home/google-review-1.png";
import StarIcon from '@mui/icons-material/Star';
import Rating from '@mui/material/Rating';
import Stack from '@mui/material/Stack';

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { Link } from 'react-router-dom';
import axios from 'axios';
import { base_url, postHeader } from '../../Helpers/request';
import { LazyLoadImage } from 'react-lazy-load-image-component';

import RightIcon from "../../Assets/images/right_icon.png";
import LeftIcon from "../../Assets/images/left_icon.png";
import { addBusinessDays } from 'date-fns';


const starIcon=<svg className='starIc' fill='#ffd119' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path d="M316.9 18C311.6 7 300.4 0 288.1 0s-23.4 7-28.8 18L195 150.3 51.4 171.5c-12 1.8-22 10.2-25.7 21.7s-.7 24.2 7.9 32.7L137.8 329 113.2 474.7c-2 12 3 24.2 12.9 31.3s23 8 33.8 2.3l128.3-68.5 128.3 68.5c10.8 5.7 23.9 4.9 33.8-2.3s14.9-19.3 12.9-31.3L438.5 329 542.7 225.9c8.6-8.5 11.7-21.2 7.9-32.7s-13.7-19.9-25.7-21.7L381.2 150.3 316.9 18z"/></svg>;

let reviewData=[
    {
        name: "Vaibhav Patil",
        review: "This Product I Ordered Is Really Beautifully Made And Very Affordable. It’s Appreciated That It Was Well Executed And The Authenticity Provided. Thank You Had A Wonderful Experience Shopping. Will Come Back For More",
        url: "https://www.google.com/maps/place/Dil+Se+Jewels/@21.1478437,72.7601317,17z/data=!4m8!3m7!1s0x3be0535106313a3f:0xdfc4baaa3a01efb!8m2!3d21.1478437!4d72.7601317!9m1!1b1!16s%2Fg%2F11kj6_8pv8"

    },
    {
        name: "Devans Bhutoria",
        review: "They Have The Best Collection Of Lab-Grown Jewelry at Reasonable Prices. You Can Also Customize Your Jewelry As Per Your Need. They Also Were Very Much Polite And Have A Good Service Too. Even They Delivered The Jewelry To Me When I Requested Them If They Could Deliver If Possible. Thank You So Much. Had A Very Good Experience!!",
        url: "https://www.google.com/maps/place/Dil+Se+Jewels/@21.1478437,72.7601317,17z/data=!4m8!3m7!1s0x3be0535106313a3f:0xdfc4baaa3a01efb!8m2!3d21.1478437!4d72.7601317!9m1!1b1!16s%2Fg%2F11kj6_8pv8"

    },
    {
        name: "Soham Sanghvi",
        review: "Me And My Family Are Permanent Customers Of Dil Se Jewels. They Never Say No For Any Design And Manufacture Jewelry To Remember. They Keep Exquisite Designs And Are Ready To Do Any kind Of Customization As Well. I Ordered A Personalized Ring For My Fiance And It Has Come Out So Nice .A Name Which Maintains Quality. 5 Star To Dil Se Jewels",
        url: "https://www.google.com/maps/place/Dil+Se+Jewels/@21.1478437,72.7601317,17z/data=!4m8!3m7!1s0x3be0535106313a3f:0xdfc4baaa3a01efb!8m2!3d21.1478437!4d72.7601317!9m1!1b1!16s%2Fg%2F11kj6_8pv8"

    },
    {
        name: "Hardik Shah",
        review: "As Beautiful As It Is Shown....Soo Happy And Satisfied To Receive It....Would Love To Be In Touch In Future As Well.... Immediate Response From The Team.... Completely Trustworthy",
        url: "https://www.google.com/maps/place/Dil+Se+Jewels/@21.1478437,72.7601317,17z/data=!4m8!3m7!1s0x3be0535106313a3f:0xdfc4baaa3a01efb!8m2!3d21.1478437!4d72.7601317!9m1!1b1!16s%2Fg%2F11kj6_8pv8"

    },
    {
        name: "Indu Banthia",
        review: "Once my ring was ready it shipped really quickly and safe. Any questions I had before, during and after the process was always answered thoroughly and quickly by the 24 hour chat service.",
        url: "https://www.google.com/maps/place/Dil+Se+Jewels/@21.1478437,72.7601317,17z/data=!4m8!3m7!1s0x3be0535106313a3f:0xdfc4baaa3a01efb!8m2!3d21.1478437!4d72.7601317!9m1!1b1!16s%2Fg%2F11kj6_8pv8"

    },
    {
        name: "APURVA DEALS",
        review: "This Product I Ordered Is Really Beautifully Made And Very Affordable. It’s Appreciated That It Was Well Executed And The Authenticity Provided. Thank You Had A Wonderful Experience Shopping. Will Come Back For More",
        url: "https://www.google.com/maps/place/Dil+Se+Jewels/@21.1478437,72.7601317,17z/data=!4m8!3m7!1s0x3be0535106313a3f:0xdfc4baaa3a01efb!8m2!3d21.1478437!4d72.7601317!9m1!1b1!16s%2Fg%2F11kj6_8pv8"

    }
];

const Customer = () => {
    const [customerData,setCustomerData] = React.useState([])
    React.useEffect(()=>{
        GetCustomerRewies();
        console.log('customerdta',customerData)
     },[])
     const GetCustomerRewies = () =>{
         axios.get(`${base_url}/common/site_reviews`,{
            headers: postHeader
        }).then(res=>{
            console.log(res)
            if(res.data.status == 1){
                setCustomerData(res.data)
            }
        }).catch(error=>{
            console.log(error)
        })
     }

    var settings2 = {
        dots: false,
        infinite: true,
        speed: 500,
        accessibility: true,
        arrows: true,
        slidesToShow: 4,
        slidesToScroll: 1,
        autoplay: false,
        responsive: [
            {
                breakpoint: 1025,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: false
                }
            },

            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: false
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 1
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    const settingsCustomerReviewSlider = {
        dots: true,
        infinite: true,
        centerMode: false,
        autoplay: true,
        speed: 1500,
        slidesToShow: 3,
        slidesToScroll: 1,
        accessibility: true,
        nextArrow: <img src={RightIcon} alt="Right icon"/>,
        prevArrow: <img src={LeftIcon}  alt="Left icon"/>,
        responsive: [
          {
            breakpoint: 1025,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 1,
              infinite: true,
              dots: true,
            },
          },
    
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
              infinite: true,
              dots: true,
            },
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              initialSlide: 1,
            },
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
        ],
      };

    return (
        <>
            {/* <section className="rcs_customer_say_section">
                <Container className="rcs_custom_home_container">
                    <Row>
                        <Col lg="12" col="12">
                            <div className="rcs_customer_say_title" data-aos="fade-up" data-aos-duration="1000">
                                <h2> What our customers say </h2>
                            </div>
                        </Col>
                        <Col lg={6} xs={12} sm={6} data-aos="fade-up" data-aos-duration="1000">
                            <div className="rcs_customer_google_rating"  onClick={() => window.open(customerData?.google_review_url, "_blank")}>
                                <div className="rcs_customer_google_rating_img">
                                    <LazyLoadImage src={Gimg} alt="Google Image" />
                                </div>
                                <div className="rcs_customer_google_rating_star">
                                    <h4>{(customerData?.average_rating)?.toFixed(1)}</h4>
                                    <Stack spacing={1}>
                                        <Rating name="half-rating-read" defaultValue={5} precision={customerData?.average_rating} size="large" readOnly />
                                    </Stack>
                                </div>
                            </div>
                        </Col>
                        <Col lg={6} xs={12} sm={6} data-aos="fade-up" data-aos-duration="1000">
                            <div className="rcs_customer_review_btn">
                                <Button className="rcs_fill_button rcs_btn_rd-0" onClick={() => window.open(customerData?.google_review_url, "_blank")}> Write a Review </Button>
                            </div>
                        </Col>
                        <Col lg={12} data-aos="fade-up" data-aos-duration="1400">
                            <div className="rcs_customer_review_slider">
                                <Slider className="rcs_customer_say_inner" {...settings2}>
                                {customerData?.data?.map(customdata => 
                                   <div className="rcs_customer_review_slide_item">
                                        <div className="rcs_customer_slide_title_img">
                                            <div className="rcs_customer_review_slide_img">
                                                <LazyLoadImage src={customdata?.profile} />
                                            </div>
                                            <div className="rcs_customer_review_slide_title">
                                                <h3>{customdata?.username}</h3>
                                                <Rating name="half-rating-read" defaultValue={5} precision={customdata?.rating} size="medium" readOnly />
                                            </div>
                                        </div>
                                        <div className="rcs_customer_slide_text_content">
                                            <p>
                                                {(customdata?.review)?.slice(0,150)}{ customdata?.review?.length > 150 ? '...' : ""}
                                            </p>
                                            <Link to="#" onClick={() => window.open(customerData?.google_review_url, "_blank")}>  Read More </Link>
                                            <LazyLoadImage src={googleReview} />
                                        </div>
                                    </div>)}
                                </Slider>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section> */}

            
            <section className="tri_customer_review_section pt-5 pb-5">
            <Container className="rcs_custom_home_container">
          <Row>
            <Col sm={12} md={12} lg={12}>
              <div className="rcs_ringstyle_title tri_text_capitalize text-center">
                <h2 className='fontOptiCentury'>What People Say</h2>
              </div>
            </Col>
            <Col sm={12} md={12} lg={12}>
              <div className="tri_customer_review_slider_box_main">
                <Slider {...settingsCustomerReviewSlider}>
                {
                    reviewData.length > 0 && reviewData.map((e,i)=>{
                        return  <div key={i} className="tri_customer_review_slider_box_inner_outer">
                    <div className="tri_customer_review_slider_box_inner">
                    <svg className='quoteIcon' fill='#bdebce' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M0 216C0 149.7 53.7 96 120 96h8c17.7 0 32 14.3 32 32s-14.3 32-32 32h-8c-30.9 0-56 25.1-56 56v8h64c35.3 0 64 28.7 64 64v64c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V320 288 216zm256 0c0-66.3 53.7-120 120-120h8c17.7 0 32 14.3 32 32s-14.3 32-32 32h-8c-30.9 0-56 25.1-56 56v8h64c35.3 0 64 28.7 64 64v64c0 35.3-28.7 64-64 64H320c-35.3 0-64-28.7-64-64V320 288 216z"/></svg>
                 
               
                      <p className='mt-4 colorPara fs16 textOverflow'>
                      {
                        e?.review.slice(0, 200)} {e?.review.length >= 200 && '...' 
                      // eslint-disable-next-line react/jsx-no-target-blank
                      } <a className='colorPara fw700' href={e?.url} target="_blank">Read more...</a>
                      </p>

                      <div className='starRating mb-3'>
                        {starIcon} {starIcon} {starIcon} {starIcon} {starIcon}
                      </div>
                      <h5>{  e?.title}</h5>
                    </div>
                  </div>
                    })
                }
               
                </Slider>
              </div>
              {/* <div className="tri_read_cusomer_review text-center">
                <NavLink to="#">Read Reviews</NavLink>
              </div> */}
            </Col>
          </Row>
          </Container>
      </section>
    
        </>
    );
}

export default Customer;


