import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { NavLink } from 'react-router-dom'


const TwoBox = (props) => {
    console.log('g1',props);
    return (
            <section className='tri_two_box_section pt-2 pb-5'>
                <Container className='pl-0 pr-0 rcs_custom_home_container'>
                    <Row className='w-100 m-auto gx-5 gy-5'>
                        <Col sm={12} md={6} lg={6} className="pl-0 pr-0 pr-md-2 mb_xs_20">
                        <div className='pl-3 pr-3'>
                        <div className="tri_two_box_bg_color2 chatbox d-flex align-items-center">
                            <img className='img-fluid w-100 rounded12' src={props?.leftSection?.img} alt="" />
                                <div className='tri_left_box_with_img'>
                                   <div>
                                     <h3 className='text-capitalize fontOptiCentury fs30'> <NavLink className="colorBlack" to="#">{props?.leftSection?.title}  </NavLink></h3>
                                    <p> <b>{props?.leftSection?.description} </b> </p>
                                    <NavLink to={props?.leftSection?.buttonUrl} className='tri_home_btn_learn_more card_button'>{props?.leftSection?.buttonText} </NavLink>
                                   </div>
                                </div>
                            </div>
                        </div>
                          
                        </Col>
                        <Col sm={12} md={6} lg={6} className="pr-0 pl-0 pl-sm-0 pl-md-0">
                        <div className='pl-3 pr-3'>
                        <div className="tri_two_box_bg_color2 chatbox d-flex align-items-center">
                            <img className='img-fluid w-100 rounded12' src={props?.rightSection?.img} alt="" />
                                <div className='tri_left_box_with_img'>
                                   <div>
                                     <h3 className='text-capitalize fontOptiCentury fs30'> <NavLink className="colorBlack" to="#">{props?.rightSection?.title} </NavLink></h3>
                                    <p> <b>Perfect for any occasion, our stunning diamond and gemstone fine jewelry collection is unique and breathtaking.</b> </p>
                                    <NavLink to={props?.rightSection?.buttonUrl} className='tri_home_btn_learn_more card_button'>{props?.rightSection?.buttonText}</NavLink>
                                   </div>
                                </div>
                            </div>
                        </div>
                      

                        </Col>
                    </Row>
                </Container>
            </section>
    )
}

export default TwoBox