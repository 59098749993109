import React from "react";
import { Button, Col, Container, Image, Row } from "react-bootstrap";

import RoundShape from "../../Assets/images/diamond-shape/orignal-img/Round.webp";
import princess from "../../Assets/images/diamond-shape/orignal-img/Princess.webp";
import cushion from "../../Assets/images/diamond-shape/orignal-img/Cushion.webp";
import emerald from "../../Assets/images/diamond-shape/orignal-img/Emerald.webp";
import oval from "../../Assets/images/diamond-shape/orignal-img/Oval.webp";
import radiant from "../../Assets/images/diamond-shape/orignal-img/Radiant.webp";
import asscher from "../../Assets/images/diamond-shape/orignal-img/Asscher.webp";
import marquise from "../../Assets/images/diamond-shape/orignal-img/Marquise.webp";
import heart from "../../Assets/images/diamond-shape/orignal-img/Heart.webp";
import pear from "../../Assets/images/diamond-shape/orignal-img/Pear.webp";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import Slider from "react-slick";
import { useHistory } from "react-router-dom";
function ShopDiamondShape() {
  var history = useHistory();
  var settings2 = {
    dots: false,
    infinite: true,
    centerMode: false,
    speed: 500,
    accessibility: true,
    arrows: true,
    slidesToShow: 10,
    slidesToScroll: 1,
    focusOnSelect: true,
    autoplay: true,
    responsive: [
      {
        breakpoint: 1025,
        settings: {
          slidesToShow: 6,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },

      {
        breakpoint: 768,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
    ],
  };
  const shopbyshape = (value) => {
    var data1 = {
      shape: value,
    };
    sessionStorage.setItem("dilse_d_filter", JSON.stringify(data1));
    history.push("/diamonds");
    window.scrollTo(0, 0);
  };

  return (
    <>
      <section className="rcs_ring_style_section mt-4">
        <Container className="rcs_custom_home_container">
          <Row>
            <Col md={12}>
              <div className="mb-0" data-aos="fade-up">
                <h2 className="text-uppercase fontOptiCentury">shop diamonds by shape</h2>
              </div>
            </Col>

            <Col lg={12} className="p-0">
              <div className="rcs_customer_review_slider" data-aos="fade-up">
                <Slider
                  className="rcs_customer_say_inner rcs_shop_diamond_slider"
                  {...settings2}
                >
                  <div className="rcs_shop_by_diamond_box_main">
                    <div
                      className="rcs_p_btn cursor"
                      onClick={() => shopbyshape("Round")}
                    >
                      <div className="rcs_shop_by_diamond_box_img">
                        <div className="rcs_shop_by_diamond_box_img_size">
                          <Image
                            className="rcs_shop_by_diamond_box_img_static"
                            src={RoundShape}
                            alt="Round"
                          />
                         
                        </div>
                        <p className="fw700 mb-0 text-uppercase"> Round </p>
                      </div>
                    </div>
                  </div>

                  <div className="rcs_shop_by_diamond_box_main">
                    <div
                      className="rcs_p_btn cursor"
                      onClick={() => shopbyshape("Princess")}
                    >
                      <div className="rcs_shop_by_diamond_box_img">
                        <div className="rcs_shop_by_diamond_box_img_size">
                          <Image
                            className="rcs_shop_by_diamond_box_img_static"
                            src={princess}
                            alt="Round"
                          />
                       
                        </div>
                        <p className="fw700 mb-0 text-uppercase"> Princess </p>
                      </div>
                    </div>
                  </div>

                  <div className="rcs_shop_by_diamond_box_main">
                    <div
                      className="rcs_p_btn cursor"
                      onClick={() => shopbyshape("Cushion")}
                    >
                      <div className="rcs_shop_by_diamond_box_img">
                        <div className="rcs_shop_by_diamond_box_img_size">
                          <Image
                            className="rcs_shop_by_diamond_box_img_static"
                            src={cushion}
                            alt="Cushion"
                          />
                
                        </div>
                        <p className="fw700 mb-0 text-uppercase"> Cushion </p>
                      </div>
                    </div>
                  </div>

                  <div className="rcs_shop_by_diamond_box_main">
                    <div
                      className="rcs_p_btn cursor"
                      onClick={() => shopbyshape("Emerald")}
                    >
                      <div className="rcs_shop_by_diamond_box_img">
                        <div className="rcs_shop_by_diamond_box_img_size">
                          <Image
                            className="rcs_shop_by_diamond_box_img_static"
                            src={emerald}
                            alt="Emerald"
                          />
                      
                        </div>
                        <p className="fw700 mb-0 text-uppercase"> Emerald </p>
                      </div>
                    </div>
                  </div>

                  <div className="rcs_shop_by_diamond_box_main">
                    <div
                      className="rcs_p_btn cursor"
                      onClick={() => shopbyshape("Oval")}
                    >
                      <div className="rcs_shop_by_diamond_box_img">
                        <div className="rcs_shop_by_diamond_box_img_size">
                          <Image
                            className="rcs_shop_by_diamond_box_img_static"
                            src={oval}
                            alt="Oval"
                          />
                        
                        </div>
                        <p className="fw700 mb-0 text-uppercase"> Oval </p>
                      </div>
                    </div>
                  </div>

                  <div className="rcs_shop_by_diamond_box_main">
                    <div
                      className="rcs_p_btn cursor"
                      onClick={() => shopbyshape("Radiant")}
                    >
                      <div className="rcs_shop_by_diamond_box_img">
                        <div className="rcs_shop_by_diamond_box_img_size">
                          <Image
                            className="rcs_shop_by_diamond_box_img_static"
                            src={radiant}
                            alt="Radiant"
                          />
                      
                        </div>
                        <p className="fw700 mb-0 text-uppercase"> Radiant </p>
                      </div>
                    </div>
                  </div>

                  <div className="rcs_shop_by_diamond_box_main">
                    <div
                      className="rcs_p_btn cursor"
                      onClick={() => shopbyshape("Asscher")}
                    >
                      <div className="rcs_shop_by_diamond_box_img">
                        <div className="rcs_shop_by_diamond_box_img_size">
                          <Image
                            className="rcs_shop_by_diamond_box_img_static"
                            src={asscher}
                            alt="Asscher"
                          />
                        
                        </div>
                        <p className="fw700 mb-0 text-uppercase"> Asscher </p>
                      </div>
                    </div>
                  </div>
          
          
                  <div className="rcs_shop_by_diamond_box_main">
                    <div
                      className="rcs_p_btn cursor"
                      onClick={() => shopbyshape("Marquise")}
                    >
                      <div className="rcs_shop_by_diamond_box_img">
                        <div className="rcs_shop_by_diamond_box_img_size">
                          <Image
                            className="rcs_shop_by_diamond_box_img_static"
                            src={marquise}
                            alt="Asscher"
                          />
                      
                        </div>
                        <p className="fw700 mb-0 text-uppercase"> Marquise </p>
                      </div>
                    </div>
                  </div>

                  <div className="rcs_shop_by_diamond_box_main">
                    <div
                      className="rcs_p_btn cursor"
                      onClick={() => shopbyshape("Heart")}
                    >
                      <div className="rcs_shop_by_diamond_box_img">
                        <div className="rcs_shop_by_diamond_box_img_size">
                          <Image
                            className="rcs_shop_by_diamond_box_img_static"
                            src={heart}
                            alt="Heart"
                          />
                        
                        </div>
                        <p className="fw700 mb-0 text-uppercase"> Heart </p>
                      </div>
                    </div>
                  </div>

                  <div className="rcs_shop_by_diamond_box_main">
                    <div
                      className="rcs_p_btn cursor"
                      onClick={() => shopbyshape("Pear")}
                    >
                      <div className="rcs_shop_by_diamond_box_img">
                        <div className="rcs_shop_by_diamond_box_img_size">
                          <Image
                            className="rcs_shop_by_diamond_box_img_static"
                            src={pear}
                            alt="Pear"
                          />
                        
                        </div>
                        <p className="fw700 mb-0 text-uppercase"> Pear </p>
                      </div>
                    </div>
                  </div>
          

           
                </Slider>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
}

export default ShopDiamondShape;
