import React, { useState, useEffect } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../../Assets/css/home.css";
import { Helmet } from 'react-helmet';
import BannerSlider from './Bannerslider';
import Insta from './insta';
import ExploreCollection from './ExploreCollection';
import Virtualappointment from './VirtualAppointment';
import Customer from './Customer';
import ShopDiamondShape from "./ShopDiamondShape";
import Gemstones from "./GemstoneShop";
import RingStyle from "./RingStyle";
import Ringbuilderhome from "./Ringbuilderhome";
import SignupTo from "./SignupTo";
import AOS from 'aos';
import 'aos/dist/aos.css' ;
import { useSelector } from 'react-redux';
import AccordionData from "./AccordionData";
import AccordionUI from "../../Components/Accordion";
import TriSignUp from "./TriSignUp";
import CustomDesign from "./CustomDesign";
import Sponser from "./Sponser";
import TwoBox from "./TwoBox";
import ProductSlider from "./productSlider";
import bg1 from "../../Assets/images/bg1.png";
import bg2 from "../../Assets/images/bg2.jpg";
import bga1 from "../../Assets/images/bgDiam.jpg";
import bga2 from "../../Assets/images/bgDiamB.jpg";

const Home = () => {
  const metaDetails = useSelector(state => state.persistedReducer.meta.meta)

  useEffect(() => {
    AOS.init({
      duration : 1000,
      // disable: 'mobile'
    });
  }, []);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{metaDetails.title}</title>
        <meta name="description" content={metaDetails.description}></meta>
        <meta name="keywords" content={metaDetails.keywords}></meta>
        {/*Og Tags */}
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={metaDetails.title} />
        <meta property="og:description" content={metaDetails.description} />
        <meta property="og:url" content={metaDetails.url} />
        <meta property="og:site_name" content="Dil Se Jewels" />
      </Helmet>
    <div className='overflow-hidden'>
    <BannerSlider />
      {/* <RingStyle />             */}
      {/* <ExploreCollection /> */}
      {/* <Ringbuilderhome /> */}
      <ShopDiamondShape />      
      {/* <Gemstones /> */}
      {/* <Virtualappointment/>    */}
      <TwoBox leftSection={{
        img: bga1,
        title: "Start with a Diamond",
        description: "Select a diamond and choose a setting to create your own diamond engagement ring.",
        buttonText: "Shop Now",
        buttonUrl: "/diamonds"
      }}  
      rightSection={{
        img: bga2,
        title: "Engagement Rings",
        description: "Select your perfect Engagement Ring with diamond. Choose from wide range of designer rings.",
        buttonText: "Shop Now",
        buttonUrl: "/ringsettings"
      }} 
       /> 

      {/* <ProductSlider/>   */}
      <TwoBox leftSection={{
        img: bg1,
        title: "Wedding Rings",
        description: "Find the perfect symbol of your love. Discover eternity diamond rings, classic bands, and unique styles.",
        buttonText: "Shop Now",
        buttonUrl: "/jewelry/wedding-rings"
      }}  
      rightSection={{
        img: bg2,
        title: "Fine jewelry",
        description: "Perfect for any occasion, our stunning diamond and gemstone fine jewelry collection is unique and breathtaking.",
        buttonText: "Shop Now",
        buttonUrl: "/jewelry/fine-jewelry"
      }} 
       /> 
      <CustomDesign/>
      {/* <Sponser/> */}
      <Customer />
     
      {/* <Insta /> */}
      <TriSignUp/>
      {/* <AccordionData/> */}
      {/* <SignupTo /> */}
     
      {/* <Colordiamond /> */}
      {/* <Letestcreation/> */}
      {/* <ServiceSection /> */}
      {/* <SignupTo /> */}
      {/* <Newsletter /> */}
      {/* <Featured /> */}
      {/*Collection1 :- Wedding band,Diamonds Earrings,Fine Jewelry */}
      {/* <Collection1 /> */}
      {/* <StateJewelry /> */}
      {/*Collection2 :- Pendants,Bracelets,Gemstones */}
      {/* <Collection2 /> */}
      {/* <IndianJewelry /> */}
      {/* <Philanthropy /> */}
      {/* <Testimonial /> */}
      {/* <Insta /> */}
      {/* <Newsletter /> */}
    </div>
    
    </>
  )
}

export default Home;